import PropTypes from 'prop-types'
import React from 'react'
import picbio from '../images/bio.jpg'
import piclouko from '../images/louko.jpg'
import picimmdeb from '../images/immdeb.jpg'
import picresurgences from '../images/resurgences.jpg'
import pictko from '../images/tko.jpg'
import picgkt from '../images/GKTrio.jpg'
import picgktaf1 from '../images/GKF1.jpg'
import picgktaf2 from '../images/GKF2.jpg'
import picbaquet1 from '../images/Baquet1.jpg'
import picbaquet2 from '../images/Baquet2.jpg'
import vidbaquet from '../images/mb.webm'
import picodland from '../images/odland.jpg'
import picrobemariee from '../images/robedemariee.jpg'
import picmoliere from '../images/moliere.jpg'
import piccinefrancophone from '../images/rencontres.jpg'
import picaigrette from '../images/aigrette.jpg'
import picdynamo from '../images/dynamo.jpg'
import piccommandes from '../images/commandes.jpg'
import piclive from '../images/live.jpg'


class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    let closeBottom = (
      <div
        className="close-bottom"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="bio"
          className={`${this.props.article === 'bio' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Qui suis-je ?</h2>
          <span className="image main">
            <img src={picbio} alt="Bio" />
          </span>
          <p>
            Je débute le violoncelle à 7 ans à Privas, en Ardèche et suis 
            diplômée de l’ENM de Villeurbanne et du CNR de Lyon. J’enseigne 
            dès 1998 dans plusieurs écoles de la région lyonnaise et je joue 
            dans diverses formations pour « apprendre le métier ».
          </p>
          <p>
            En 2017, suite à un grand bouleversement, je décide de me consacrer 
            à la scène. Dès lors je perpétue et développe de fructueuses 
            collaborations. La liste étant assez longue je vous invite à vous 
            rendre sur les pages PROJETS, COLLABORATIONS et COMMANDES pour avoir un 
            inventaire à peu près exhaustif.
          </p>
          <p>
            Je n’aime pas mettre les personnes dans des cases et encore moins 
            moi-même alors disons que je fais de la Musique. J’ai des influences 
            très diverses allant de Jean-Sébastien Bach à Persefone en passant 
            par Bobby Lapointe, Shannon Wright  ou Jedi Mind Tricks. Si vous ne 
            connaissez pas ces artistes et musiciens je vous invite à les découvrir.
            <br/>
            Je travaille acoustique ou amplifiée, avec <em>looper</em> et pédales s’il faut, 
            classique, metal, rock, chanson, rap, musique improvisée ou écrite, en 
            concert ou en studio.
          </p>
          <p>
            Violoncelliste de métier je joue aussi de la basse et je chante pour divers 
            projets. Je compose de la musique de scène (théâtre et danse), je crée des 
            arrangements cordes etc. 
          </p>
          <p>
            J’aime autant interpréter que créer, le style n’est qu’une étiquette. 
            Si la musique est sincère, l’émotion est au rendez-vous.
          </p>
          {close}
          {closeBottom}
        </article>

        <article
          id="projects"
          className={`${this.props.article === 'projects' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projets</h2>
          <h3>LOUKO DUO : cabaret de musique de chambre</h3>
          <span className="image main">
            <img src={piclouko} alt="LouKo Duo" />
          </span>
          <p>
            Mon projet le plus personnel et le plus long dans le temps. 
            Créé en 2005 avec mon amie Coline Verger au violon ce duo milite 
            pour la reconnaissance de cette formation de musique de chambre.
          </p>
          <ul className="icons">
            <li>
              <a href="https://soundcloud.com/lucie-lacour-253173999/extrait-louko?in=lucie-lacour-253173999/sets/extraits" target="_blank" rel="noopener noreferrer" className="icon fa-play-circle">
                <span className="label">Écouter un extrait</span>
              </a>
            </li>
            <a href="https://soundcloud.com/lucie-lacour-253173999/extrait-louko?in=lucie-lacour-253173999/sets/extraits" target="_blank" rel="noopener noreferrer">
              <em>Friedrich Hermann - Extrait du 1<sup>er</sup> mouvement du Grand duo brillant</em>
            </a>
          </ul>
          <ul className="icons">
            <li>
              <a href="http://loukoduo.free.fr/" target="_blank" rel="noopener noreferrer" className="icon fa-external-link">
                <span className="label">Site officiel</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/LouKo-Duo-188472367836626/" target="_blank" rel="noopener noreferrer" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/user/loukoduo" target="_blank" rel="noopener noreferrer" className="icon fa-youtube">
                <span className="label">Youtube</span>
              </a>
            </li>
          </ul>
          <hr/>
          <h3>IMMOBILE/DEBOUT : trio rock</h3>
          <span className="image main">
            <img src={picimmdeb} alt="Immobile/Debout" />
          </span>
          <p>
            Tout aussi important que LouKo duo. Depuis 2015 j’ai rejoins mes 
            amis de 15 ans Jérôme et Lionel Aubernon dans un univers post 
            rock avec textes en français et beaucoup de plaisir !
          </p>
          <ul className="icons">
            <li>
              <a href="https://soundcloud.com/lucie-lacour-253173999/crames?in=lucie-lacour-253173999/sets/extraits-2022" target="_blank" rel="noopener noreferrer" className="icon fa-play-circle">
                <span className="label">Écouter un extrait</span>
              </a>
            </li>
            <a href="https://soundcloud.com/lucie-lacour-253173999/crames?in=lucie-lacour-253173999/sets/extraits-2022" target="_blank" rel="noopener noreferrer">
              <em>Cramés</em>
            </a>
          </ul>
          <ul className="icons">
            <li>
              <a href="https://www.facebook.com/immobiledebout/" target="_blank" rel="noopener noreferrer" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UC_SXD2kqpEYzq5nTCw0JocQ" target="_blank" rel="noopener noreferrer" className="icon fa-youtube">
                <span className="label">Youtube</span>
              </a>
            </li>
          </ul>
          <hr/>
          <h3>TRIO RÉSURGENCE(S) : musique de chambre</h3>
          <span className="image main">
            <img src={picresurgences} alt="Trio Résurgence(s)" />
          </span>
          <p>
            Créé en septembre 2020 avec Julie Dubois au violon et Sarah Simon au
            piano. Nous avons décider de n'interpréter que des compositrices trop
            peu souvent présentées en concert. Ce répertoire est sublime et donc
            à découvrir.
          </p>
          {close}
          {closeBottom}
        </article>

        <article
          id="collab"
          className={`${this.props.article === 'collab' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Collaborations</h2>
          <h3>GABRIEL KELLER TRIO | GABRIEL KELLER AND FRIENDS</h3>
          <p>
            Deux déclinaisons pour un même univers musical, le rock progressif du musicien et compositeur lyonnais Gabriel Keller.
          </p>
          <span className="image main">
            <img src={picgkt} alt="Gabriel Keller Trio" />
          </span>
          <span className="image main">
            <img src={picgktaf1} alt="Gabriel Keller and friends" />
            </span>
          <span className="image main">
            <img src={picgktaf2} alt="Gabriel Keller and friends" />
          </span>
          <ul className="icons">
            <li>
              <a href="https://soundcloud.com/lucie-lacour-253173999/gabriel-keller-nothing-human?in=lucie-lacour-253173999/sets/extraits-2022" target="_blank" rel="noopener noreferrer" className="icon fa-play-circle">
                <span className="label">Écouter un extrait</span>
              </a>
            </li>
            <a href="https://soundcloud.com/lucie-lacour-253173999/gabriel-keller-nothing-human?in=lucie-lacour-253173999/sets/extraits-2022" target="_blank" rel="noopener noreferrer">
              <em>Nothing Human</em>
            </a>
          </ul>
          <ul className="icons">
            <li>
              <a href="https://gabrielkeller.fr/" target="_blank" rel="noopener noreferrer" className="icon fa-external-link">
                <span className="label">Site officiel</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/GabrielKellerMusique" target="_blank" rel="noopener noreferrer" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/gabriel_keller_musique/" target="_blank" rel="noopener noreferrer" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/user/kelton38000" target="_blank" rel="noopener noreferrer" className="icon fa-youtube">
                <span className="label">Youtube</span>
              </a>
            </li>
          </ul>
          <hr/>
          <h3>Balade pour violoncelle et chambre noire</h3>
          <p>
            Suite à la commande du Hangar 717, Johan Allenbach et moi-même avons créé un spectacle déambulatoire 
            autour de Maurice Baquet et Robert Doisneau. Cette déambulation est en cours d'adaptation pour les 
            salles de spectacles.
          </p>
          <span className="image main">
            <img src={picbaquet1} alt="Balade pour violoncelle et chambre noire" />
          </span>
          <span className="image main">
            <img src={picbaquet2} alt="Balade pour violoncelle et chambre noire" />
          </span>
          <ul className="icons">
            <li>
              <a href={vidbaquet} target="_blank" rel="noopener noreferrer" className="icon fa-play-circle">
                <span className="label">Écouter un extrait</span>
              </a>
            </li>
            <a href={vidbaquet} target="_blank" rel="noopener noreferrer">
              <em>Hommage à Maurice Baquet au Hangar 717</em>
            </a>
          </ul>
          <hr/>
          <h3>ÖDLAND : Chanson folk</h3>
          <span className="image main">
            <img src={picodland} alt="Ödland" />
          </span>
          <p>
            Lorenzo Papace, Alizée et Léa Bingöllü m’ont ouvert leur porte magique, 
            derrière se trouve un monde musical enchanté. En 2012 avec Mélodie Carrechio 
            à la flûte nous enregistrons l’album Galaktoboureko. L’aventure se poursuit 
            depuis au gré des vents et des budgets.
          </p>
          <ul className="icons">
            <li>
              <a href="https://soundcloud.com/lucie-lacour-253173999/odland?in=lucie-lacour-253173999/sets/extraits" target="_blank" rel="noopener noreferrer" className="icon fa-play-circle">
                <span className="label">Écouter un extrait</span>
              </a>
            </li>
            <a href="https://soundcloud.com/lucie-lacour-253173999/odland?in=lucie-lacour-253173999/sets/extraits" target="_blank" rel="noopener noreferrer">
              <em>Ménades et satyres</em>
            </a>
          </ul>
          <ul className="icons">
            <li>
              <a href="https://odland.fr/" target="_blank" rel="noopener noreferrer" className="icon fa-external-link">
                <span className="label">Site officiel</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/odlandmusic/" target="_blank" rel="noopener noreferrer" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/odlandmusic/" target="_blank" rel="noopener noreferrer" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          <hr/>
          <h3>ELENA MELODY : Chanson jazz minimaliste</h3>
          <p>
            Le disque « Majorelle », une ode aux bleus de la mélancolie, est sorti en 
            mars 2020. De cette rencontre est née une amitié forte et l'envie de construire 
            d'autres projets ensemble.
          </p>
          <ul className="icons">
            <li>
              <a href="https://soundcloud.com/elena-melody" target="_blank" rel="noopener noreferrer" className="icon fa-soundcloud">
                <span className="label">Soundcloud</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/Elenamelody.music/" target="_blank" rel="noopener noreferrer" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
          </ul>
          <hr/>
          <h3>ERWAN PINARD : Chanson française rock</h3>
          <p>
            Ses deux musiciens sont mes amis d'Immobile/Debout. Il m'a demandé de 
            collaborer à son disque « L'indicible (treize titres tristes) » sorti en novembre 2020. 
            Je l'ai fait avec beaucoup de plaisir et une fracture de tibia en plus.
          </p>
          <ul className="icons">
            <li>
              <a href="https://soundcloud.com/lucie-lacour-253173999/o-solitude?in=lucie-lacour-253173999/sets/extraits-2022" target="_blank" rel="noopener noreferrer" className="icon fa-play-circle">
                <span className="label">Écouter un extrait</span>
              </a>
            </li>
            <a href="https://soundcloud.com/lucie-lacour-253173999/o-solitude?in=lucie-lacour-253173999/sets/extraits-2022" target="_blank" rel="noopener noreferrer">
              <em>Ô solitude</em>
            </a>
          </ul>
          <ul className="icons">
            <li>
              <a href="https://www.youtube.com/channel/UCTjbppc88JP4O2_XXrS453w" target="_blank" rel="noopener noreferrer" className="icon fa-youtube">
                <span className="label">Youtube</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/erwanpinardofficiel" target="_blank" rel="noopener noreferrer" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://erwanpinard.bandcamp.com/releases" target="_blank" rel="noopener noreferrer" className="icon fa-bandcamp">
                <span className="label">Bandcamp</span>
              </a>
            </li>
          </ul>
          <hr/>
          <h3>Hoxy More: Chanson française</h3>
          <p>
            Hoxy More travaille en collaboration avec Gabriel Keller et m'a demandé 
            d'enregistrer quelques titres sur son album « Échappée » qui sortira en mars 2022. 
            C'est une belle rencontre humaine et une jolie découverte musicale.
          </p>
          <ul className="icons">
            <li>
              <a href="https://www.facebook.com/HoxyMore" target="_blank" rel="noopener noreferrer" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/HoxyMoreMaite" target="_blank" rel="noopener noreferrer" className="icon fa-youtube">
                <span className="label">Youtube</span>
              </a>
            </li>
          </ul>
          <hr/>
          <h3>LA THÉORIE DU K.O. : pièce de théâtre</h3>
          <span className="image main">
            <img src={pictko} alt="La théorie du K.O." />
          </span>
          <p>
            Une opportunité incroyable qui m’a été offerte par la compagnie 
            « Cause Toujours! » : participer au festival <em>off</em> d’Avignon en 2018 
            et 2019, avec d’autres dates en région. Écrite par Rémy.S, mise en 
            scène par Aude Charollais, interprétée par Cédric Saulnier et 
            moi-même. La musique a été composée par Anne-Colombe Martin. 
            Sans oublier Jonathan Argémi à la création lumière et Charlie Henry 
            à la régie.
          </p>
          <ul className="icons">
            <li>
              <a href="https://www.associationcausetoujours.com/" target="_blank" rel="noopener noreferrer" className="icon fa-external-link">
                <span className="label">Site officiel</span>
              </a>
            </li>
            <li>
              <a href="https://www.billetreduc.com/233666/evtcrit.htm" target="_blank" rel="noopener noreferrer" className="icon fa-external-link">
                <span className="label">Bilet réduc</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/associationcausetoujours/" target="_blank" rel="noopener noreferrer" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
          </ul>
          {close}
          {closeBottom}
        </article>

        <article
          id="orders"
          className={`${this.props.article === 'orders' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Commandes</h2>
          <span className="image main">
            <img src={piccommandes} alt="Commandes" />
          </span>
          <h3>« Molière » de Georges Sand : Pièce de théâtre</h3>
          <span className="image main">
            <img src={picmoliere} alt="Molière" />
          </span>
          <p>
            Par le théâtre Ishtar, mise en scène par Maïté Cussey. <br/>
            La compagnie m'a commandé la musique originale qui accompagne la pièce. 
            Je la joue en direct et je deviens un vrai personnage.<br/>
            Cette pièce est une re-création, 
            la première a eu lieu à Bron (69) le 14 septembre 2021.
          </p>
          <ul className="icons">
            <li>
              <a href="https://theatreishtar.com/professionnels/moliere-george-sand/" target="_blank" rel="noopener noreferrer" className="icon fa-external-link">
                <span className="label">Molière</span>
              </a>
            </li>
            <li>
              <a href="https://soundcloud.com/lucie-lacour-253173999/moliere?in=lucie-lacour-253173999/sets/extraits-2022" target="_blank" rel="noopener noreferrer" className="icon fa-play-circle">
                <span className="label">Écouter un extrait</span>
              </a>
            </li>
            <a href="https://soundcloud.com/lucie-lacour-253173999/moliere?in=lucie-lacour-253173999/sets/extraits-2022" target="_blank" rel="noopener noreferrer">
              <em>« Molière » de Georges Sand</em>
            </a>
          </ul>
          <hr/>
          <h3>La robe de mariée : Pièce de théâtre</h3>
          <span className="image main">
            <img src={picrobemariee} alt="La robe de mariée" />
          </span>
          <p>
            Avec Marie-Catherine Conti. Texte: Katherine L. Bataillie. Mise en scène 
            Chris Berna assistée par Tristan Bruemmer. Musiques: Jean-Christophe Rosaz 
            et moi-même. Production: les curieux polyglottes. Création les 11 et 12 
            septembre 2021 à l'atelier des arts (60). Seule en scène et violoncelliste. 
            L'histoire vraie d'une femme qui va broder, à presque 60 ans, sa robe de mariée 
            à l'asile. Un texte sublime porté par une merveilleuse comédienne.
          </p>
          <ul className="icons">
            <li>
              <a href="https://atelierdesarts.weebly.com/la-robe-de-marieacutee-11--12-sept-2021.html" target="_blank" rel="noopener noreferrer" className="icon fa-external-link">
                <span className="label">La robe de mariée</span>
              </a>
            </li>
          </ul>
          <hr/>
          <h3>Liberté je crie ton nom : Pièce de théâtre</h3>
          <p>
            Par la compagnie « Cause Toujours! ». Commande en co-écriture avec Charlie Henry 
            et interprétation d'une musique originale qui est diffusée. Sujet sensible sur 
            le choix et la liberté. Création en octobre 2021 dans la Loire.
          </p>
          <ul className="icons">
            <li>
              <a href="https://www.associationcausetoujours.com/libert%C3%A9-je-crie-ton-nom" target="_blank" rel="noopener noreferrer" className="icon fa-external-link">
                <span className="label">Liberté je crie ton nom</span>
              </a>
            </li>
            <li>
              <a href="https://soundcloud.com/lucie-lacour-253173999/liberte?in=lucie-lacour-253173999/sets/extraits-2022" target="_blank" rel="noopener noreferrer" className="icon fa-play-circle">
                <span className="label">Écouter un extrait</span>
              </a>
            </li>
            <a href="https://soundcloud.com/lucie-lacour-253173999/liberte?in=lucie-lacour-253173999/sets/extraits-2022" target="_blank" rel="noopener noreferrer">
              <em>Liberté je crie ton nom</em>
            </a>
          </ul>
          <hr/>
          <h3>Valse pour A. : Composition et interprétation</h3>
          <span className="image main">
            <img src={piccinefrancophone} alt="Rencontres du cinéma francophone en Beaujolais" />
          </span>
          <p>
            Composition et interprétation de la musique du film-annonce des 26<sup>èmes</sup> rencontres 
            du cinéma francophone en Beaujolais. Cinéma « Les 400 coups », 2021.
          </p>
          <ul className="icons">
            <li>
              <a href="https://autrecinema.fr/edition-2021/" target="_blank" rel="noopener noreferrer" className="icon fa-external-link">
                <span className="label">L'autre cinéma</span>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/watch?v=58QjexIySBE" target="_blank" rel="noopener noreferrer" className="icon fa-youtube">
                <span className="label">Youtube</span>
              </a>
            </li>
          </ul>
          <hr/>
          <h3>Musiques de ballet</h3>
          <span className="image main">
            <img src={picaigrette} alt="Aigrette" />
          </span>
          <p>
            Estelle Olivier, danseuse et professeure de danse, m’a commandé une musique 
            de ballet pour son corps amateur en danse contemporaine qu’elle encadre. La 
            création a eu lieu en mai 2019 à Vienne lors de la nuit des musées. J’ai 
            interprété la musique en direct.
          </p>
          <ul className="icons">
            <li>
              <a href="https://soundcloud.com/lucie-lacour-253173999/aigrette?in=lucie-lacour-253173999/sets/extraits" target="_blank" rel="noopener noreferrer" className="icon fa-play-circle">
                <span className="label">Écouter un extrait</span>
              </a>
            </li>
            <a href="https://soundcloud.com/lucie-lacour-253173999/aigrette?in=lucie-lacour-253173999/sets/extraits" target="_blank" rel="noopener noreferrer">
              <em>Aigrette (Live)</em>
            </a>
          </ul>
          <span className="image main">
            <img src={picdynamo} alt="Dynamo" />
          </span>
          <p>
            La compagnie Dynamo m’a commandé la musique pour un projet intergénérationnel 
            à Thizy-lès Bourg autour du corps et de la danse. Ce projet a été créé en 
            direct en mai 2019.
          </p>
          <hr/>
          <h3>MAIS AUSSI...</h3>
          <p>
            J'ai préparé les arrangements cordes sur un spectacle autour des chansons de Barbara. 
            Suite à l’invitation de Bertrand Lamure, libraire, j’ai proposé un moment littérature 
            et musique en 2019 et une variation Saint-Saëns en 2021. J’ai accompagné le chanteur 
            Pandore sur scène, collaboré avec le pianiste Jérôme Kus, l’ensemble relecture et création 
            dirigé par Carlos Molina, le Théâtre du Grabuge pour la pièce « Requiem pour les prétendants », 
            Jean-Luc Peilhon sur des compositions de musique turque, Olivier Roinsol pour la création de 
            sa pièce pour violoncelle seul « En attendant...waiting for » etc.
          </p>
          {close}
          {closeBottom}
        </article>

        <article
          id="concerts"
          className={`${this.props.article === 'concerts' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Concerts</h2>
          <span className="image main">
            <img src={piclive} alt="Concerts" />
          </span>

          <h3>Murmures Beaujolais</h3>
          <p>
            Création artistique pour une comédienne, une archéologue, une plasticienne et deux musicien·ne·s autour de la Saône.<br/>
            Embarcadère de Jassans-Riottier (01)<br/>
            Samedi 16 septembre, 11h<br/>
            Gratuit
          </p>
          <hr/>

          <h3>Gabriel Keller Trio</h3>
          <p>
            <a href="https://gabrielkeller.fr/">Rock progressif acoustique</a><br/>
            <a href="https://www.destination-beaujolais.com/agenda-beaujolais/les-concerts-du-kiosque-gabriel-keller-trio-6589611.html">Concerts du kiosque Villefranche sur Saône (69)</a><br/>
            Samedi 16 septembre, 17h<br/>            
            Gratuit
          </p>
          <hr/>

          <h3>LouKo Trio</h3>
          <p>
            <a href="http://loukoduo.free.fr/">LouKo</a> : Autour des saisons de Vivaldi<br/>
            Maison d'arrêt de Fleury-Mérogis<br/>
            Concert non ouvert au public
          </p>
          <hr/>

          <h3>Gabriel Keller Trio</h3>
          <p>
            <a href="https://gabrielkeller.fr/">Rock progressif acoustique</a><br/>
            Tournée d'automne
            <ul>
            <li>Mercredi 27 septembre, 20h <a href="https://www.facebook.com/events/1198111204208836/">Barrel House, Mâcon</a> (71)</li>
            <li>Jeudi 28 septembre, 19h <a href="https://www.facebook.com/events/745661794012266/">Médiathèque, Corpeau</a> (21)</li>
            <li>Vendredi 29 septembre, 19h30 <a href="https://www.facebook.com/events/254041683631021/">Au bon coin Lorentz, Teting sur Nied</a> (57)</li>
            <li>Samedi 30 septembre, 19h30 <a href="https://www.facebook.com/events/2453034518195010/">La chaouée, Metz</a> (57)</li>
            <li>Dimanche 1er octobre, 18h <a href="https://www.facebook.com/events/557262299722248/">Temple de St Avold</a> (57)</li>
            <li>Vendredi 6 octobre, 18h <a href="https://www.bm-lyon.fr/les-rendez-vous-des-bibliotheques-municipales-de-lyon/#/animation/27/date/32">Festival Ampli à la bibliothèque Lacassagne Lyon 3</a> (69)</li>
            <li>Samedi 7 octobre, 17h <a href="https://www.facebook.com/events/755561676342120/?ref=newsfeed">Festival Ampli à la bibliothèque de Chassieu</a> (69)</li>
            </ul>
          </p>
          <hr/>

          <h3>La robe de mariée</h3>
          <p>
            <a href="http://www.theatresdecompiegne.com/-la-robe-de-mariee-406">Théâtre musical</a> avec Marie-Catherine Conti et Lucie Lacour au violoncelle.<br/>
            Samedi 14 octobre et dimanche 15 octobre (horaires à confirmer)<br/>
            Théâtre de Senlis (60)
          </p>
          <hr/>

          <h3>Immobile/Debout </h3>
          <p>
            <a href="https://immobiledebout.bandcamp.com/">Post-rock poétique mais énervé</a><br/>
            Vendredi 27 octobre, 21h<br/>
            <a href="https://www.brasseriegessienne.fr/qg?fbclid=IwAR1OgQMfNnK1kUb4KUJ-U9UY3WMGBOMPGrp-tMb6GCut-3OHoTSGZ4mLPvo">QG</a><br/>
            Ornex (01)
          </p>
          <hr/>

          <h3>Molière par George Sand</h3>
          <p>
            <a href="https://theatreishtar.com/professionnels/moliere-george-sand/">Théâtre musical par le théâtre Ishtar</a><br/>
            Jeudi 2 novembre, 15h<br/>
            Sortie de résidence (69)<br/>
            Plus de détail en message privé
          </p>
          <hr/>

          <h3>28<sup>èmes</sup> rencontres du cinéma francophone</h3>
          <p>
            Remise des prix des 28<sup>èmes</sup> rencontres du cinéma francophone<br/>
            Dimanche 12 novembre, 17h<br/>
            Cinéma les 400 coups Villefranche sur Saône (69)
          </p>
          <hr/>

          <h3>Gabriel Keller and friends</h3>
          <p>
            <a href="https://gabrielkeller.fr/">Rock progressif</a><br/>
            Sortie de résidence<br/>
            Vendredi 1<sup>er</sup> décembre<br/>
            Lissiaco<br/>
            Lissieu (69)
          </p>

        
          {close}
          {closeBottom}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form name="contact" method="POST" action="/submit-success" netlify>
            <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Votre nom</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Votre adresse e-mail</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Envoyer" className="special" />
              </li>
              <li>
                <input type="reset" value="Annuler" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a href="mailto://lucie.lacour@gmail.com" className="icon fa-envelope">
                <span className="label">Courriel</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/lucie.lacour.5" target="_blank" rel="noopener noreferrer" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
