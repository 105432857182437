import React from 'react'
import PropTypes from 'prop-types'
import svgbassclef from '../images/bassclef.svg'

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <img src={svgbassclef} alt="&#119074;"/>
        </div>
        <div className="content">
            <div className="inner">
                <h1>Lucie Lacour</h1>
                <p>
                &nbsp;Violoncelliste&nbsp;<br/>
                &nbsp;Compositrice&nbsp;
                </p>
            </div>
        </div>
        <nav>
            <ul>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('bio')}}>Bio</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('projects')}}>Projets</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('collab')}}>Collaborations</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('orders')}}>Commandes</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('concerts')}}>Concerts</a></li>
                <li><a href="javascript:;" onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
