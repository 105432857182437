import React from 'react'
import PropTypes from 'prop-types'

const Footer = (props) => (
    <footer id="footer" style={props.timeout ? {display: 'none'} : {}}>
        <p className="copyright">&copy; Gatsby Starter - Dimension. Design: <a href="https://html5up.net">HTML5 UP</a>. Built with: <a href="https://www.gatsbyjs.org/">Gatsby.js</a></p>
        <p className="copyright">Image by <a href="https://pixabay.com/users/OpenClipart-Vectors-30363/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=154546">OpenClipart-Vectors</a> from <a href="https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=154546">Pixabay</a></p>
        <p className="copyright">Crédits photos : Bernard Chevaleyre | Maud Colly | Gabriel Keller | <a href="http://dominik.fusina.net/">Dominik Fusina</a> | <a href="https://www.garanceli.fr/">Garance Li</a> | Julie Daubié | Lionel Aubernon | Pascal Sauriat | <a href="https://victorgelis.book.fr/">Victor Gélis</a> | <a href="https://galeriejeanlouismandon.com/portfolio-item/evelyne-rogniat/">Evelyne Rogniat</a> | <a href="https://www.krystelthibaud.com/about">Krystel Thibaud</a></p>
    </footer>
)

Footer.propTypes = {
    timeout: PropTypes.bool
}

export default Footer
